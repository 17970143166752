const initialState = {
  maxProfit: 0,
  totalProfit: 0,
  totalTransaction: 0
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_TOTAL_TRANSACTION':
      return {
        ...state,
        totalTransaction: state.totalTransaction + action.payload
      }
    case 'UPDATE_TOTAL_PROFIT':
      return {
        ...state,
        totalProfit: state.totalProfit + action.payload
      }
    case 'UPDATE_MAX_PROFIT':
      return {
        ...state,
        maxProfit: state.maxProfit + action.payload
      }
    default:
      return { ...state }
  }
}
export default dashboardReducer
